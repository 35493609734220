<template>
  <div v-if="playInfoList.length">
    <div
      class="com-game-video"
    >
      <div class="com-game-video-title">
        精彩视频
      </div>
      <ul class="com-game-video-list">
        <li
          v-for="(item, index) in playInfoList"
          :key="index"
          class="com-game-video-list-item"
        >
          <div class="com-game-video-list-pic">
            <img
              :src="item.coverImg"
              alt="picture"
            >
            <div
              @click="onBtnClick(item, index, realIndex)"
              class="com-game-video-list-pic-play"
            />
          </div>
          <span class="com-game-video-list-text">{{ item.title }}</span>
        </li>
      </ul>
      <div class="com-game-video-line" />
    </div>
  </div>
</template>

<script>
import reportApi from '@/api/report';

export default {
  name: 'NewGameVideo',
  props: {
    groupId: {
      type: String,
      default: () => {},
    },
    playInfoList: {
      type: Array,
      default: () => {},
    },
    gameCode: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      realIndex: 0,
    };
  },
  mounted() {
    reportApi({
      pageid: '2902',
      moduleid: 97,
      eventtype: 2,
      extendid: 1,
      gameCode: this.gameCode,
    }, '新游介绍详情页-精彩视频曝光');
  },
  methods: {
    onBtnClick(item, index, realIndex) {
      this.$emit('videoClick', item, index, realIndex, 'true');
      reportApi({
        pageid: '2902',
        moduleid: 97,
        eventtype: 3,
        extendid: 1,
        gameCode: this.gameCode,
      }, '新游介绍详情页-精彩视频点击');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
